<template>
<transition appear name="fade">
  <div class="row" :key="feedItem.id">
    <div v-if="feedItem.image" class="col">
      <div class="image">
      <img :src="feedItem.image" >
      </div>
    </div>
    <div :class="contentsize">
      <div class="content">
        <div class="user">
          <img :src="feedItem.user_image" >
          <h2 :class="headingSize">{{ feedItem.user_full_name }}</h2>
        </div>
        <p :class="textSize" v-html="contentNoUrls"></p>
      </div>
    </div>
  </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    feedItem: {}
  },
  computed: {
    textSize () {
      console.log(this.cont)
      if (this.contentNoUrls.length > 150) {
        return 'xxxxssFont'
      }
      if (this.contentNoUrls.length > 100) {
        return 'xxxssFont'
      }
      if (this.contentNoUrls.length > 60) {
        return 'xxssFont'
      }
      if (this.contentNoUrls.length > 50) {
        return 'xssFont'
      }
      if (this.contentNoUrls.length > 40) {
        return 'xsFont'
      }
      if (this.contentNoUrls.length > 30) {
        return 'smFont'
      }
    },
    headingSize () {
      if (this.$props.feedItem.user_full_name.length > 30) {
        return 'sm'
      }
    },
    contentsize () {
      if (this.$props.feedItem.image) {
        return 'col'
      }
      return 'col'
    },
    contentNoUrls () {
      return this.$props.feedItem.text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '').replace(/(<([^>]+)>)/gi, '')
    }
  }
})
export default class SocialFeed extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.social-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 4%;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

  .row {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 4%;
    .col {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 70%;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      overflow-wrap: break-word;
      &:first-child {
        width: 40%;
        justify-content: flex-end;
      }
      &:last-child {
          background-color: #fff;
      }
      .image {
       width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
         width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: right;
        object-position: right;
        }
      }
    }
  }

  .content {
    padding: 10px 10% 10px 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    color: #000E54;
    background-color: #fff;
    width: auto;
    overflow: hidden;
    overflow-wrap: break-word;
    a, a:active, a:focus {
      color: #000E54 !important;
      text-decoration: none !important;
    }
    p {
      overflow-wrap: break-word;
      font-size: 8vh;
      width: 100%;
      &.smFont {
        font-size: 7.5vh;
      }
      &.xsFont {
        font-size: 7.5vh;
      }
      &.xxsFont {
        font-size: 6.5vh;
      }
      &.xxssFont {
        font-size: 6vh;
      }
      &.xxxssFont {
        font-size: 5.5vh;
      }
      &.xxxxssFont {
        font-size: 5vh;
      }
    }
  }

  .user {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #D74100;
    align-items: center;
    overflow-wrap: break-word;
    a, a:active, a:focus {
      color: #000E54 !important;
      text-decoration: none !important;
    }
    h2 {
      overflow-wrap: break-word;
      font-size: 4em;
      margin-bottom: .5rem;
      &.sm {
        font-size: 3em;
      }
    }
    img {
      margin-right: 30px;
      margin-top: 20px;
      object-fit: contain;
      width: 75px;
      height: 75px;
    }
  }
</style>
