
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    feedItem: {}
  },
  computed: {
    textSize () {
      console.log(this.cont)
      if (this.contentNoUrls.length > 150) {
        return 'xxxxssFont'
      }
      if (this.contentNoUrls.length > 100) {
        return 'xxxssFont'
      }
      if (this.contentNoUrls.length > 60) {
        return 'xxssFont'
      }
      if (this.contentNoUrls.length > 50) {
        return 'xssFont'
      }
      if (this.contentNoUrls.length > 40) {
        return 'xsFont'
      }
      if (this.contentNoUrls.length > 30) {
        return 'smFont'
      }
    },
    headingSize () {
      if (this.$props.feedItem.user_full_name.length > 30) {
        return 'sm'
      }
    },
    contentsize () {
      if (this.$props.feedItem.image) {
        return 'col'
      }
      return 'col'
    },
    contentNoUrls () {
      return this.$props.feedItem.text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '').replace(/(<([^>]+)>)/gi, '')
    }
  }
})
export default class SocialFeed extends Vue {}
