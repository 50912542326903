<template>
<div class="orange-bg" :class="getBackground()">
    <SocialFeed v-if="currentItem.id" :feedItem="currentItem" />
    <div class="tag">#CuseGameDay</div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SocialFeed from './components/SocialFeed.vue'
import axios from 'axios'

const params = new URLSearchParams(window.location.search)

@Options({
  components: {
    SocialFeed
  },
  data () {
    return {
      feedItems: [],
      currentItem: {},
      itemInterval: null,
      feedInterval: null,
      params: {}
    }
  },
  mounted () {
    console.log('Loading Feed')
    this.getFeeds()
    this.setSlideChange()
    this.updateFeed()
  },
  methods: {
    getFeeds () {
      axios.get('https://api.curator.io/v1/feeds/ef7d028b-42c9-4f4f-a565-1482499cc8ca/posts?api_key=6b18e84a-f30e-4a1d-b7b8-d2abd73dee1f').then((data) => {
        const filteredArray = data.data.posts.filter((obj1: any) => !this.$data.feedItems.some((obj2: any): boolean => obj2.id === obj1.id))
        console.log('Added Items')
        this.$data.feedItems.push(...filteredArray)
      })
    },
    getBackground () {
      return (params.get('sport') || 'domesocial') + '_bg/'
    },
    setSlideChange () {
      this.$data.itemInterval = setInterval(() => {
        console.log('item change')
        this.$data.currentItem = this.$data.feedItems.shift()
        if (this.$data.feedItems.length === 0) {
          this.getFeeds()
        }
      }, 5000)
    },
    updateFeed () {
      this.$data.feedInterval = setInterval(() => {
        console.log('update feed')
        this.getFeeds()
      }, 6000)
    }
  }
})
export default class App extends Vue {}
</script>

<style lang="scss">
  .tag {
    position: absolute;
    left: 4%;
    bottom: 5%;
    font-size: 3.75vw;;
    color: #ffffff;
    text-shadow: #000E54 3px 3px 5px;
    font-weight: 900;
  }

  .orange-bg {
    height: 100%;
    background-image: url('/domesocial_bg.png');
    background-size: 100% 100%;
    background-position: left;
    position: relative;
  }

  .lax-bg {
    height: 100%;
    background-image: url('/lacrosse_bg.png');
    background-size: 100% 100%;
    background-position: left;
    position: relative;
  }
  </style>
